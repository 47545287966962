import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/components/Box';
import Image from 'common/components/Image';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import heartImage from 'common/assets/image/charity/heart-red.png';
import NavbarWrapper, { MenuWrapper, Button } from '../Navbar/navbar.style';

import BlockWrapper, {
  ContentWrapper,
  List,
  Item,
  ImageWrapper,
} from './promotionBlock.style';

const runSite = () => {
  window.location.href= 'https://donate.wildaid.org/give/347498/#!/donation/checkout'
}


const PromotionBlock = ({ row, col }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        promotionDataTwo {
          image {
            publicURL
          }
          slogan
          title
          text1
          text2
          text3
          text4
          option1
          option2
          option3
        }
      }
    }
  `);

  const {
    slogan,
    title,
    text1,
    text2,
    text3,
    text4,
    image,
    option1,
    option2,
    option3,
  } = data.charityJson.promotionDataTwo;

  return (
    <BlockWrapper id="ourCommunity">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ImageWrapper>
              <Image src={image.publicURL} alt="Charity Landing" />
            </ImageWrapper>
          </Box>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading as="h5" content={slogan} />
              <Heading content={title} />
              <Text content={text1} />
              <a href="https://mailchi.mp/1d2159ff366f/misool-foundation-new-film-about-our-recycling-programme" target="_blank" style={{textDecoration:'underline'}}><Text content={text2} /></a>
              <Text content={text3} />

              <a href="https://www.misoolfoundation.org/whoweare" style={{float:'left', marginRight:'15px', marginBottom:'15px'}}  className="learn__more-btn">
                <span className="hyphen" />
                <span className="btn_text">Learn More </span>
              </a>
          <Button onClick={()=>runSite()}>

              <span className="text">Donate Now</span>
              <Image src={heartImage} alt="Charity Landing" />

          </Button>
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// PromotionBlock style props
PromotionBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// PromotionBlock default style
PromotionBlock.defaultProps = {
  // PromotionBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // PromotionBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default PromotionBlock;
